<template>
  <div class="card" style="min-height: 200px">
    <vue3-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      pdf-content-width="100%"
      :htmlToPdfOptions="htmlToPdfOptions"
      ref="html2Pdf"
    >
      <template  v-slot:pdf-content>
        <div class="card-body">
          <div class="p-1">
            <div class="row">
              <div class="col-12 col-lg-6">
                <img :src="company.logo64" :alt="company.name" class="img-fluid" style="max-height: 100px;"/>
              </div>
              <div class="col-12 col-lg-6 mt-2 text-right" v-if="company.name">
                <p class="h4">{{ company.name }}</p>
                <p>
                  Address: {{company.address}}, <br>
                  Phone: {{company.phone}}, <br>
                  E-mail: {{company.email}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 px-2">
            <p class="h1 m-0 text-center">
              Requisition
            </p>
            <hr>
          </div>
          <div class="row p-2">
            <div class="col-12 col-lg-6">

              <p>
                <strong> Requisition from </strong> <br>
                <span v-if="requisition.user">{{requisition.user.name}}</span> <br>
                <span v-if="requisition.department">{{requisition.department.name}}</span>
              </p>

              <p>
                <strong> Project Details </strong> <br>
                <span v-if="requisition.project">{{requisition.project.name}}</span>
              </p>

              <p>
                <strong> Business Unit </strong> <br>
                <span v-if="requisition.business">{{requisition.business.name}}</span>
              </p>

            </div>
            <div class="col-12 col-lg-6 text-end">
              <p><strong>Requisition No: </strong> {{requisition.requisition_number}}</p>
              <p><strong>Requisition Date: </strong> {{requisition.date}}</p>
              <p><strong>Estimated Required Date: </strong> </p>
            </div>
            <div class="col-12 table-responsive py-2">
              <requisition-table
                :has-item-details="Boolean(requisition.has_item_detail)"
                :table-items="tableItems"> 

              </requisition-table>
            </div>
            <div class="col-12">
              {{requisition.description}}
            </div>
          </div>
          <div class="row p-2">
            <div class="col-12 col-lg-3 text-left mt-2">
              <p class="mb-3">
                <strong>Prepared By: </strong>
              </p>
              <p v-if="requisition.user">
                {{requisition.user.name}} <br>
                {{requisition.department.name}} <br>

              </p>
            </div>
            <div class="col-12 col-lg-3 text-center mt-2">
              <p class="mb-3">
                <strong>Reviewed By: </strong>
              </p>

            </div>
            <div class="col-12 col-lg-3 text-center mt-2">
              <p class="mb-3">
                <strong>Reviewed By: </strong>
              </p>

            </div>
            <div class="col-12 col-lg-3 text-right mt-2">
              <p class="mb-3">
                <strong>Approved By: </strong>
              </p>

            </div>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <div class="card-footer">
      <button class="btn btn-primary float-end" @click="exportToPDF">Download as PDF</button>
    </div>
    <Loader v-if="loader"/>
  </div>
</template>
<script>
import handleCompany from "@/services/modules/company";
import Loader from "@/components/atom/LoaderComponent";
import RequisitionTable from "@/components/atom/RequisitionTable";
import handleRequisitions from "@/services/modules/procurement/requisition";
import {onMounted, ref, inject, computed} from "vue";
import {useRoute} from "vue-router";
import Vue3Html2pdf from "vue3-html2pdf";
import figureFormatter from '@/services/utils/figureFormatter'
export default {
  name: "Print",
  components: {
    Loader,
    RequisitionTable,
    Vue3Html2pdf
  },
  setup() {
    const company = ref({});
    const loader = ref(false);
    const requisition = ref({});
    const html2Pdf = ref(null);
    const {fetchCompanyInfo} = handleCompany();
    const {fetchSingleRequisition} = handleRequisitions();
    const {commaFormat, currencyToWord} = figureFormatter();
    const route = useRoute();
    const showError =  inject('showError');
    const requisition_number = computed(() => {
      return requisition.value.requisition_number;
    });
    const htmlToPdfOptions = ref({
      margin: 0.5,
      filename: requisition_number,
      image: {
        type   : 'jpeg',
        quality: 0.98
      },
      enableLinks: false,
      html2canvas: {
        scale  : 1,
        useCORS: true
      },
      jsPDF: {
        unit       : 'in',
        format     : 'a4',
        orientation: 'portrait'
      }
    });
    const tableItems = computed(() => {
      return requisition.value.has_item_detail ? requisition.value.general : requisition.value.account;
    })
    const exportToPDF = () => {
      html2Pdf.value.generatePdf();
    }
    onMounted(() => {
      loader.value = true;
      const companyQuery = `?company_id=${route.params.companyId}`;
      const fetchCompany = fetchCompanyInfo(route.params.companyId);
      const fetchRequisition = fetchSingleRequisition(route.params.requisitionId, companyQuery);
      Promise.all([
        fetchCompany.then(res => {
          company.value = res.data;
        }),
        fetchRequisition.then(res => {
          requisition.value = res.data;
        })
      ])
        .then( () => loader.value = false )
        .catch( (err) => {
          showError("Something went wrong");
          loader.value = false;
        } )
    });
    return {
      company,
      loader,
      requisition,
      tableItems,
      html2Pdf,
      htmlToPdfOptions,
      requisition_number,
      exportToPDF,
      commaFormat,
      currencyToWord,
    }
  }
}
</script>