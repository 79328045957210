<template>
  <table class="table table-bordered">
    <thead>
    <tr>
      <th>Particulars</th>
      <th v-if="hasItemDetails">Unit</th>
      <th class="text-right" v-if="hasItemDetails">Unit Price</th>
      <th class="text-right" v-if="hasItemDetails">Required QTY</th>
      <th class="text-right">Stock in hand</th>
      <th>Required Date</th>
      <!-- <th>Remarks</th> -->
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, index) in tableItems" :key="index">
      <td>
        <p>{{hasItemDetails ? item.product.name : item.account_head.name}}</p>
      </td>
      <td v-if="hasItemDetails">{{ item?.product?.description?.unit?.name }}</td>
      <td class="text-right" v-if="hasItemDetails">{{commaFormat(item.rate)}}</td>
      <td class="text-right" v-if="hasItemDetails">{{commaFormat(item.quantity)}}</td>
      <td class="text-right">{{getStock(item.product_id)}}</td>
      <td>{{ item.formatted_expected_delivery_date }}</td>
      <!-- <td>{{item.description}}</td> -->
    </tr>
    </tbody>
  </table>
</template>
<style scoped>
.table thead th, .table tfoot th {
  vertical-align: top;
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0px !important;
  padding: 0.5rem 0.5rem!important;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem !important;
}
</style>
<script setup>
import { ref,computed } from "@vue/runtime-core"
import figureFormatter from '@/services/utils/figureFormatter'
import {inject, onUpdated, watch} from "vue";
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const {commaFormat} = figureFormatter()
const showError = inject('showError');
const {fetchProductStock} = handlePurchaseAndSales();
const route = useRoute();
const productStock = ref(null);

const pr = defineProps({
  tableItems: {
    type: Object,
    required: true
  },
  hasItemDetails: {
    type: Boolean,
    required: true
  }
})
const fetchStock = (id) => {
  let query = `?company_id=${route.params.companyId}`;
  fetchProductStock(id, query).then( res => {
    if(res.status === false) {
      productStock.value = null;
      return;
    }
    productStock.value = res.data.stock;
  })
}

function getStock(product_id){
  fetchStock(product_id);
  return productStock.value;
}
</script>